// ------- animation js -------------
// new WOW().init();

// ---------- header js  ------------

/*
$(document).ready(function() {
    var header = $("#myHeader"); 

    $(window).scroll(function() {
        if ($(window).scrollTop() > 50) {
            header.addClass("sticky");
        } else {
            header.removeClass("sticky");
        }
    });
});
*/

